/* tslint:disable:no-string-literal */
import {Component, HostListener} from '@angular/core'

import {Platform} from '@ionic/angular'
import {ejsSchedulerInit} from './shared/configs/ejs-scheduler.config'
import {ejsDatetimepickerInit} from './shared/configs/ejs-datetimepicker.config'
import packagejson from 'package.json'
import {environment} from '../environments/environment'
import {PwaAppUpdateService} from './services/appUpdate/pwa-app-update.service'
import {TouchscreenService} from './services/touchscreen.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  @HostListener('touchstart')
  touchOccurred() {
    this.touchscreenService.isTouchscreen = true
  }
  public version: string = packagejson.version
  declare window: any

  private origConsole: object = {
    log: window.console.log,
    info: window.console.info,
    debug: window.console.debug,
    trace: window.console.trace,
    time: window.console.time,
    timeEnd: window.console.timeEnd,
  }

  constructor(
    private platform: Platform,
    // private debugService: DebugService,
    // private appUpdateNotification: AppUpdateNotificationService,
    private appUpdateService: PwaAppUpdateService,
    private touchscreenService: TouchscreenService
  ) {
    this.initializeApp()
    ejsSchedulerInit()
    ejsDatetimepickerInit()

    if (environment.production) {
      this.appUpdateService.init()
    }

    if (environment.production && !environment.staging) {
      this.adjustLoggingOnProduction()
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
    })
  }

  private adjustLoggingOnProduction() {
    if (window) {
      window['enableLogging'] = () => {
        this._enableLogging()
        if (localStorage && !localStorage.getItem('isLoggingEnabled')) {
          localStorage.setItem('isLoggingEnabled', '1')
        }
      }
      window['disableLogging'] = () => {
        this._disableLogging()
        if (localStorage && localStorage.getItem('isLoggingEnabled')) {
          localStorage.removeItem('isLoggingEnabled')
        }
      }
      // Restore stored enabled/disabled state
      if (localStorage && localStorage.getItem('isLoggingEnabled')) {
        this._enableLogging()
      } else {
        this._disableLogging()
      }
    }
  }

  private _disableLogging() {
    window.console.log =
    window.console.info =
    window.console.debug =
    window.console.trace =
    window.console.time =
    window.console.timeEnd =
      () => {}
  }

  private _enableLogging() {
    window.console.log = this.origConsole['log']
    window.console.info = this.origConsole['info']
    window.console.debug = this.origConsole['debug']
    window.console.trace = this.origConsole['trace']
    window.console.time = this.origConsole['time']
    window.console.timeEnd = this.origConsole['timeEnd']
  }

}
