import {FeathersModel} from './feathersModel'
import {ToggleableModules} from '../features/config/ToggleableModules'

export enum ConfigKeys {
  DO_KDY_MUZE_ASISTENT_ZMENIT_NABIZENY_CAS = 'assistant_availability_change_by_assistant_limit_minutes_to_next_monday',
  DO_KDY_MUSI_ASISTENT_POTVRDIT_ASISTENCI = 'confirmation_of_next_week_assistances_by_assistant_limit_minutes_to_next_monday',
  ODKDY_JE_POZDE_OBJEDNANA_ASISTENCE_HODINY = 'late_order_assistance_limit_minutes_to_next_monday',
  POZDE_OBJEDNANA_JESTLI_SE_VZTAHUJE_NA_KOORDINATORY = 'late_order_assistance_limit_applies_to_coordinators',
  ODKDY_JE_POZDE_ZRUSENA_JIZ_POTVRZENA_ASISTENCE_HODINY = 'late_canceled_confirmed_assistance_limit_minutes_to_assistance',
  DOKDY_JE_MOZNA_EDITACE_PROBEHLYCH_ASISTENCI_DNY = 'editing_past_assistance_by_coordinator_days',
  ORDINACNI_HODINY_START_NO_TZ = 'working_hhmm_start_no_timezone',
  ORDINACNI_HODINY_END_NO_TZ = 'working_hhmm_end_no_timezone',
  STORNO_BEZ_LIMITU_O_VIKENDECH_A_SVATCICH = 'late_canceled_assistance_no_limit_on_weekend_and_holidays',
  VYCHOZI_DOBA_PREJEZDU = 'default_transit_duration_minutes',

  TOGGLEABLE_MODULES = 'active_modules'
}

export const ConfigTooltipTexts = {
  // [ConfigKeys.ODKDY_JE_POZDE_OBJEDNANA_ASISTENCE_HODINY]: 'Udává minimální počet hodin před začátkem týdne, ve kterém se uskuteční asistence, před kterým  musí být asistence založena, aby nebyla označena jako “Pozdě objednaná”. Např. založit asistenci jde nejpozději ve středu 12:00 minulého týdne i.e. 108 hodin od začátku týdne, ve kterém se plánovaná asistence nachází.',
  [ConfigKeys.ODKDY_JE_POZDE_OBJEDNANA_ASISTENCE_HODINY]: 'Asistence je pozdě založená, když je založena nastavený počet hodin (a později) před začátkem týdne, ve kterém má probíhat. Např. při nastavení 48 hodin budou pozdě objednané všechny asistence na příští týden založené o víkendu.',
  [ConfigKeys.POZDE_OBJEDNANA_JESTLI_SE_VZTAHUJE_NA_KOORDINATORY]: 'Při zaškrtnutí se bude parametr “Počet hodin před začátkem týdne, ve kterém asistence probíhá” vztahovat i na koordinátory.',
}

const ConfigDefaultValues = {
  [ConfigKeys.DO_KDY_MUZE_ASISTENT_ZMENIT_NABIZENY_CAS]: 14400,
  [ConfigKeys.DO_KDY_MUSI_ASISTENT_POTVRDIT_ASISTENCI]: 14400,
  [ConfigKeys.ODKDY_JE_POZDE_OBJEDNANA_ASISTENCE_HODINY]: 14400,
  [ConfigKeys.POZDE_OBJEDNANA_JESTLI_SE_VZTAHUJE_NA_KOORDINATORY]: true,
  [ConfigKeys.ODKDY_JE_POZDE_ZRUSENA_JIZ_POTVRZENA_ASISTENCE_HODINY]: 7200,
  [ConfigKeys.DOKDY_JE_MOZNA_EDITACE_PROBEHLYCH_ASISTENCI_DNY]: 7,
  [ConfigKeys.ORDINACNI_HODINY_START_NO_TZ]: '0700',
  [ConfigKeys.ORDINACNI_HODINY_END_NO_TZ]: '1400',
  [ConfigKeys.VYCHOZI_DOBA_PREJEZDU]: 30,
  [ConfigKeys.TOGGLEABLE_MODULES]: new ToggleableModules(),
}

export class Config extends FeathersModel {
  static serviceName = 'configs'

  name: ConfigKeys
  value: any

  constructor(name: ConfigKeys, value?: any) {
    super()

    this.name = name
    if (value) {
      this.value = value
    } else {
      this.value = this.getDefaultValue()
    }
  }

  private getDefaultValue() {
    return ConfigDefaultValues[this.name]
  }
}
