import { Injectable } from '@angular/core'
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker'
import {filter, map} from 'rxjs/operators'
import {ToastController} from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class PwaAppUpdateService {

  constructor(
    private swUpdate: SwUpdate,
    private toaster: ToastController
  ) { }

  async init() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))
      )
        .subscribe(evtRes => {
          console.log('New version ready', evtRes)
          this.showNewVersionToast((evtRes.available.appData as {version: string}).version)
        })
    }

    await this.initVersionCheck()
  }

  private async initVersionCheck(frequencySeconds = 60) {
    await this.swUpdate.checkForUpdate()
    setInterval(async () => {
      await this.swUpdate.checkForUpdate()
    }, frequencySeconds * 1000)
  }

  private async showNewVersionToast(version: string) {
    const toast = await this.toaster.create({
      header: `Nová verze Aspiku (${version}) je k dispozici!`,
      position: 'top',
      buttons: [
        {
          side: 'end',
          icon: 'reload-outline',
          text: 'Aktualizovat',
          handler: () => {
            console.log('Favorite clicked')
            location.reload()
          }
        }, {
          text: '',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked')
          }
        }
      ]
    })
    await toast.present()
  }
}
