import {FeathersBackendService, FeathersDataStoreHolder, FeathersServiceEvent} from './feathers.service'
import {fromEventPattern, Observable, Subject} from 'rxjs'
import {filter, map, skipWhile, takeUntil} from 'rxjs/operators'
import copy from 'fast-copy'

export abstract class FeathersBaseDataStore<RecordType, StoreType> {
  protected store: StoreType
  protected TAG = 'FeathersDataStore'

  protected created$: Observable<StoreType>
  protected updated$: Observable<StoreType>
  protected patched$: Observable<StoreType>
  protected removed$: Observable<StoreType>

  private customCallbackEvents$$ = new Subject<StoreType>()
  protected customCallbackEvents$ = this.customCallbackEvents$$.asObservable()

  private unsubscribe = new Subject<void>()
  protected allowEvents = false

  protected query: { }

  readonly id: number

  abstract defaultStoreValue: StoreType

  constructor(protected feathersService: any, protected dataStoreCounter: FeathersDataStoreHolder) {
    this.id = this.dataStoreCounter.registerNewDataStore(this)

    this.created$ = fromEventPattern(
      handler => this.feathersService.on('created', handler),
      handler => {
        console.log(this.TAG, `${this.id}/${this.feathersService.path}`, 'destroy DataStore', this)
        this.dataStoreCounter.unregisterDataStore(this.id)
        this.feathersService.off('created', handler)
        this.onDestroy()
        return
      }
    ).pipe(
      skipWhile(_ => !this.allowEvents),
      map((record: RecordType) => this.onCreated(record))
    )

    this.updated$ = fromEventPattern(
      handler => this.feathersService.on('updated', handler),
      handler => {
        // console.log(this.TAG, `${this.id}/${this.feathersService.path}`, this, 'removed updated listener')
        this.feathersService.off('updated', handler)
      }
    ).pipe(
      skipWhile(_ => !this.allowEvents),
      map((record: RecordType) => this.onUpdated(record)),
      filter(e => e != null)
    )

    this.patched$ = fromEventPattern(
      handler => this.feathersService.on('patched', handler),
      handler => {
        // console.log(this.TAG, `${this.id}/${this.feathersService.path}`, this, 'removed patched listener')
        this.feathersService.off('patched', handler)
      }
    ).pipe(
      skipWhile(_ => !this.allowEvents),
      map((record: RecordType) => this.onPatched(record)),
      filter(e => e != null),
    )

    this.removed$ = fromEventPattern(
      handler => this.feathersService.on('removed', handler),
      handler => {
        // console.log(this.TAG, `${this.id}/${this.feathersService.path}`,  this, 'removed removed listener')
        this.feathersService.off('removed', handler)
      }
    ).pipe(
      skipWhile(_ => !this.allowEvents),
      map((record: RecordType) => this.onRemoved(record)),
      filter(e => e != null),
    )

    console.log(this.TAG, 'new datastore created, id:', this.id)
  }

  addCustomServiceCallback<T>(service: FeathersBackendService, event: FeathersServiceEvent, callback: (store: StoreType, record: T) => StoreType): void {
    fromEventPattern(
      handler => service.on(event, handler),
      handler => service.off(event, handler)
    ).pipe(
      skipWhile(_ => !this.allowEvents),
      map((record: T) => {
        this.customCallbackEvents$$.next(callback(this.store, record))
      }),
      takeUntil(this.unsubscribe)
    ).subscribe()
  }

  public initialize(query: { }): Observable<StoreType> {
    this.store = this.defaultStoreValue

    return this.find(query).pipe(
      map((a: StoreType) => copy(a))
    )
  }

  public abstract find(query): Observable<StoreType>

  protected abstract onCreated(record: RecordType): StoreType

  protected abstract onUpdated(record: RecordType): StoreType

  protected abstract onPatched(record: RecordType): StoreType

  protected abstract onRemoved(record: RecordType): StoreType

  private onDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
    this.store = null
  }

  destroy() {
    this.onDestroy()
  }
}
