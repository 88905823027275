import {Injectable} from '@angular/core'
import {ModalController} from '@ionic/angular'
import {AdvancedToastComponent} from './advanced-toast.component'

@Injectable({
  providedIn: 'root'
})
export class AdvancedToasterService {

  constructor(private modalCtrl: ModalController) {
    
  }

  async present(opts: {message: string, ionIconName?: string, cssClasses?: string[], durationMs?: number, iconAnimation?: 'spin', spinner?: boolean}) {
    console.log(opts)

    const modal = await this.modalCtrl.create({
      component: AdvancedToastComponent,
      componentProps: {message: opts.message, ionIconName: opts.ionIconName, durationMs: opts.durationMs, iconAnimation: opts.iconAnimation, spinner: opts.spinner},
      cssClass: [...opts.cssClasses, 'advanced-toast-modal'],
      showBackdrop: false,
      backdropDismiss: false
    })
    await modal.present()

    return modal
  }

}
