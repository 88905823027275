<div *ngIf="showView"
     [popper]="tooltipcontent"
     [popperShowOnStart]="true"
     [popperDisableStyle]="true"
>
</div>

<popper-content #tooltipcontent>
  <div class="wrapper">
    <ion-spinner *ngIf="loading; else content"></ion-spinner>
    <ng-template #content>
      <div [innerHTML]="inputHtml$ | async"></div>
    </ng-template>
  </div>
</popper-content>
