import {Injectable} from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class TouchscreenService {
  isTouchscreen = false
  constructor() {
  }
}
