import {NgModule} from '@angular/core'
import {PreloadAllModules, RouterModule, Routes} from '@angular/router'
import {NonAuthGuard} from './services/guards/non-auth-guard.service'
import {AuthGuard} from './services/guards/auth-guard.service'

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule), canActivate: [NonAuthGuard] },
  { path: 'menu', loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule), canActivate: [AuthGuard] },
  {
    path: 'superadmin-dashboard',
    loadChildren: () => import('./pages/superadmin/superadmin-dashboard/superadmin-dashboard.module').then( m => m.SuperadminDashboardPageModule)
  },
  // Catch-all for non-existing routes (must be last):
  { path: '**', redirectTo: '/menu/app/tabs/welcome', pathMatch: 'full', },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
