import {inject, Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router'

import {FeathersService} from '../feathers.service'
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class NonAuthGuardService {

  constructor(
    private router: Router,
    public feathersService: FeathersService,
  ) {
  }

  // Guard method for views that must be logged out (e.g. login/register)
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const retUrl = state.url
    console.log('NonauthGuardService(%s): checking saved auth token...', retUrl)
    const redirectUrl = '/menu/app/tabs/welcome' // TODO: (soon) this should not be defined in the service. Refactor it out of here.

    return this.feathersService.reauthenticate()
      .then(() => {
        // Force login guard
        console.log('NonauthGuardService(%s): has valid saved auth token, redirecting to %s.', retUrl, redirectUrl)
        return this.router.parseUrl(redirectUrl) // Angular >= 7.1 router
      })
      .catch((err) => {
        console.log(err)
        // Ok
        console.log('NonauthGuardService(%s): no valid saved auth token, ok.', retUrl)
        return true
      })
      
  }
}

export const NonAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(NonAuthGuardService).canActivate(next, state)
}
