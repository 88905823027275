import {Assistant} from './assistant'
import {AssistanceStatus, AssistanceStatusName} from './assistanceStatus'
import {FeathersModel} from './feathersModel'
import {AssistantAvailability} from './assistantAvailability'
import {AssistanceStatusManagerService} from '../services/assistance-status-manager.service'
import {Assistance} from './assistance'

export interface AssistantAssignmentBase {
  statusId: number
  assistantAvailabilityId: number
  assistantId: number
  assistanceId: number
  assistantFeedback: string
}

export class AssistantAssignment extends FeathersModel implements AssistantAssignmentBase {
  static serviceName = 'assistances-assistants-assignments'

  assistant: Assistant
  status: AssistanceStatus
  assistant_availability: AssistantAvailability
  oldAvailability: AssistantAvailability
  statusId: number
  assistantAvailabilityId: number
  assistantId: number
  assistanceId: number
  assistance: Assistance
  assistantFeedback: string

  public setAssistant(assistant: Assistant) {
    this.assistant = assistant || null
    // this.assistantId = assistant ? assistant.id : null
  }

  public setAssistantAvailability(aa: AssistantAvailability) {
    if (this.assistant_availability) {
      this.oldAvailability = this.assistant_availability
    }

    this.setAssistant(aa ? aa.assistant : null)
    this.assistant_availability = aa || null
    // this.assistanceAvailabilityId = aa ? aa.id : null
  }
  removeAssistantAvailability() {
    this.setAssistantAvailability(null)
  }

  initStatus(statusService: AssistanceStatusManagerService) {
    this.setStatusTo(statusService.getStatusByName(AssistanceStatusName.HAS_ASSISTANT))
  }

  setStatusTo(status: AssistanceStatus) {
    this.status = status
    this.statusId = status.id
  }

  getAvailability(includeFake: boolean = true) {
    if (includeFake && this.assistant && !this.assistant_availability) {
      return AssistantAvailability.createFromProps(AssistantAvailability, {assistant: this.assistant})
    }
    return this.assistant_availability
  }


  turnSubresourcesIntoFrontendModels() {
    this.assistant = this.assistant ? Assistant.createFromProps(Assistant, this.assistant) : this.assistant
    this.status = this.status ? AssistanceStatus.createFromProps(AssistanceStatus, this.status) : this.status
    this.assistant_availability = this.assistant_availability ? AssistantAvailability.createFromProps(AssistantAvailability, this.assistant_availability) : this.assistant_availability
    this.assistance = this.assistance ? Assistance.createFromProps(Assistance, this.assistance) : this.assistance
  }
}

export class AssistantAssignmentList {
  private TAG = 'AssistantAssignmentList'
  private static TAG = 'AssistantAssignmentList'

  get length() {
    return this.assignments.length
  }
  private assignments: AssistantAssignment[] = []
  public assistantNames: string

  updateByAssistantIdImmutable(assignment: AssistantAssignment) {
    const newList = this.clone()
    const index = newList.getAssignmentsPrivateArray().findIndex(a => a.assistantId === assignment.assistantId)
    newList.getAssignmentsPrivateArray()[index] = assignment
    return newList
  }

  clone(): AssistantAssignmentList {
    const copy = new (this.constructor as new () => AssistantAssignmentList)()
    Object.assign(copy, this)
    return copy
  }

  static initializeAsFeathersModels(unconvertedAssignments: any | any[]): AssistantAssignmentList {
    // console.log('assistantAssignments initializeAsFeathersModels unconvertedAssignments', unconvertedAssignments)
    const list = new AssistantAssignmentList()
    if (unconvertedAssignments && unconvertedAssignments.assignments) {
      unconvertedAssignments.assignments.forEach(a => list.add(AssistantAssignment.createFromProps(AssistantAssignment, a)))
    } else if (unconvertedAssignments && Array.isArray(unconvertedAssignments)) {
      unconvertedAssignments.forEach(a => list.add(AssistantAssignment.createFromProps(AssistantAssignment, a)))
    }
    return list
  }

  private updateAssistantNames() {
    this.assistantNames = this.getDelimitedAssistantNames()
  }

  setAssignments(assignments: AssistantAssignment[]) {
    this.clear()
    assignments.forEach(a => this.add(a))
  }

  add(assignment: AssistantAssignment) {
    this.assignments.push(assignment)
    this.updateAssistantNames()
    return true
  }

  addUniqueForAvailability(assignment: AssistantAssignment) {
    if (this.existsWithSameAssistanceId(assignment)) {
      // throw 'Duplicate assignment';
      return false
    }

    this.assignments.push(assignment)
    this.updateAssistantNames()
    return true
  }

  addUniqueForAssistance(assignment: AssistantAssignment) {
    if (this.existsWithSameAssistant(assignment)) {
      // throw 'Duplicate assignment';
      return false
    }

    this.assignments.push(assignment)
    this.updateAssistantNames()
    return true
  }

  clear() {
    this.assignments = []
    this.updateAssistantNames()
  }

  // There cannot be two assignments with the same assistant on assistance
  existsWithSameAssistant(assignment: AssistantAssignment): boolean {
    const found = this.assignments.find(a => a.assistant && assignment.assistant && a.assistant.id === assignment.assistant.id)
    return !!found
  }

  // There can however be two assignemnts with same assistant on assistantAvailability, but not the same assistance
  existsWithSameAssistanceId(assignment: AssistantAssignment): boolean {
    const found = this.assignments.find(a => a.assistanceId && assignment.assistanceId && a.assistanceId === assignment.assistanceId)
    return !!found
  }

  hasAssistant(assistantId: number): boolean {
    return this.assignments.some(a => a.assistantId === assistantId)
  }

  getDelimitedAssistantNames(delimiter = ', ') {
    const names = []
    this.assignments.forEach(a => {
      if (a && a.assistant && a.assistant.user) {
        names.push(a.assistant.user.fullName())
      }
    })
    return names.join(delimiter)
  }

  isEmpty() {
    return this.assignments.length === 0
  }

  hasAnyAssistant() {
    return this.assignments.some((a: AssistantAssignment) => !!a.assistant)
  }

  setStatusesTo(status: AssistanceStatus) {
    console.log('setStatusesTo', status.humanReadableName)
    this.assignments.forEach(a => a.setStatusTo(status))
  }

  getAvailabilities(includeFakeAvailabilities: boolean = false): AssistantAvailability[] {
    const availabilities: AssistantAvailability[] = []
    this.assignments.forEach(a => availabilities.push(a.getAvailability(includeFakeAvailabilities)))
    return availabilities
  }

  getAvailabilitiesWithStatus(includeFakeAvailabilities: boolean = false): {availability: AssistantAvailability, status: AssistanceStatus}[] {
    const res = []

    this.assignments.forEach(a => {
      res.push({
        availability: a.getAvailability(includeFakeAvailabilities),
        status: a.status
      })
    })

    return res
  }

  getStatuses() {
    const statuses: AssistanceStatus[] = []
    this.assignments.forEach(a => statuses.push(a.status))
    return statuses
  }

  includesStatus(statusName: AssistanceStatusName) {
    return this.getStatuses().some(status => status.name === statusName)
  }

  getAssignmentIds() {
    const ids: number[] = []
    this.assignments.forEach(a => ids.push(a.id))
    return ids
  }

  getAssistantIds() {
    const ids: number[] = []
    this.assignments.forEach(a => ids.push(a.assistant.id))
    return ids
  }

  getUniqueAssistances(): Assistance[] {
    // console.log('getUniqueAssistances', this)
    const res = {}
    this.assignments.map(assignment => assignment.assistance).map(a => {
      // console.log('getUniqueAssistances', res, a.id, a)
      if (!a) {
        console.error(this.TAG, 'Missing assistance on assignment', this)
        return
      }
      return res[a.id] = a
    })
    return Object.values(res)
  }

  getAssignmentsPrivateArray() {
    return this.assignments
  }

  getAssignmentByAssistantId(id: number) {
    // return this.getAvailabilities(true).find(a => a.assistant.id === id)
    return this.assignments.find(a => a.assistant?.id === id)
  }

  getAssignmentsByStatusName(statusName: AssistanceStatusName): AssistantAssignment[] {
    return this.assignments.filter(assignment => assignment.status.name === statusName)
  }

  includesAssistantWithStatus(assistantId: number, statuses: AssistanceStatusName[]): boolean {
    return this.assignments.some(assignment => statuses.includes(assignment.status.name) && assignment.assistant.id === assistantId)
  }

  /**
   * Compares two assignmentLists and returns whether they differ in length, ids or status.
   * @param assistantAssignments
   */
  areAssignmentsDifferent(assistantAssignments: AssistantAssignmentList): boolean {
    return AssistantAssignmentList.areAssignmentsDifferent(this, assistantAssignments)
  }

  /**
   * Compares two assignmentLists and returns whether they differ in length, ids or status.
   */
  static areAssignmentsDifferent(list1: AssistantAssignmentList, list2: AssistantAssignmentList): boolean {
    if (!list1 && !list2) return false
    if (!list1 || !list2) return true

    const assignments1 = list1.getAssignmentsPrivateArray()
    const assignments2 = list2.getAssignmentsPrivateArray()

    if (assignments1.length !== assignments2.length) return true

    // same length of ass1 and ass2 guaranteed
    for (const a1 of assignments1) {
      const correspondingA2 = assignments2.find(a2 => a1.assistantId === a2.assistantId)
      console.log(AssistantAssignmentList.TAG, 'areAssignmentsDifferent', a1, correspondingA2)
      if (!correspondingA2) return true

      if (a1.statusId !== correspondingA2.statusId) return true
    }

    return false

  }

  getAssistants() {
    return this.getAssignmentsPrivateArray().map(a => a.assistant)
  }
}
