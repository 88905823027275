import {RRuleSet, rrulestr} from 'rrule'
import {RecurrentFeathersModel} from '../../models/RecurrentFeathersModel'
import {addDays, addYears, isWithinInterval, subDays, subHours} from 'date-fns'
import {coerceToArray} from '../../services/utils'
import {FullCalendarHelper} from '../scheduling/assistance-full-calendar/fullCalendarHelper'
import {DateRange, DateUtils} from '../../services/date-utils.service'

export class RruleJsHelper {
  static TAG = 'RruleJSHelper'

  /**
   * Expands the recurrence pattern of a parent event within a given date range.
   *
   * @param parent The parent event with a recurrence pattern.
   * @param [range] The date range in which to expand the recurrence pattern. If not provided, a 1-year range centered around the parent event's date is used.
   * @returns An array of occurrences within the specified date range.
   */
  static expand(parent: RecurrentFeathersModel, range?: DateRange): Date[] {
    // console.log('expand', parent, range)
    let rruleStr = FullCalendarHelper.generateRruleStringFromRecurrentModel(parent)

    if (rruleStr.endsWith(';')) rruleStr = rruleStr.substr(0, rruleStr.length - 1)


    let rruleSet: RRuleSet
    try {
      rruleSet = rrulestr(rruleStr, {forceset: true}) as RRuleSet
    } catch (e) {
      console.warn(e)
    }

    // console.debug(this.TAG, 'expand()', rruleStr, rruleSet)

    if (!range) range = {
      from: addYears(parent.getDateFrom(), -1),
      to: addYears(parent.getDateFrom(), 1)
    }

    let occurrences: Date[] = rruleSet.between(subDays(range.from, 1), addDays(range.to, 1), true)

    if (!occurrences) return []

    occurrences = coerceToArray(occurrences).map(o => {
      const tzDiffHours = DateUtils.getTimezoneDifference(parent.getDateFrom(), o) + (parent.getDateFrom().getTimezoneOffset() / -60 )// +1 nebo +2
      return subHours(o, tzDiffHours)
    })

    occurrences = occurrences.filter(o => isWithinInterval(o, {start: range.from, end: range.to}))
    // console.debug(this.TAG, 'expand(), occurrences:', occurrences)

    return occurrences

  }







}

