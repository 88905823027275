import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FloatoverComponent} from "./floatover.component";
import {IonicModule} from "@ionic/angular";
import {NgxPopperjsModule} from "ngx-popperjs";

@NgModule({
  declarations: [FloatoverComponent],
	imports: [
		CommonModule,
		IonicModule,
		NgxPopperjsModule
	],
  exports: [FloatoverComponent]
})
export class FloatoverModule { }
