import {FeathersBaseDataStore} from './feathersBaseDataStore'
import {defer, from, merge, Observable} from 'rxjs'
import {debounceTime, filter, map, shareReplay, tap} from 'rxjs/operators'
import {Paginated} from '@feathersjs/feathers'

export class FeathersGenericDataStore<RecordType> extends FeathersBaseDataStore<RecordType, RecordType[]> {
  defaultStoreValue: RecordType[]

  public find(query): Observable<RecordType[]> {
    this.query = query

    const dataFromFind$: Observable<RecordType[]> = defer(() => from(this.feathersService.find({query}))).pipe(
      // tap(e => console.log('got from find', e)),
      // first(),
      map((response: Paginated<RecordType> | RecordType[]) => {
        console.log('response', response)
        if (response !== null && 'data' in response) {
          this.store = response?.data || this.defaultStoreValue
        } else {
          this.store = response as RecordType[] || this.defaultStoreValue
        }
        console.log(`On find ${this.id}/${this.feathersService.path}:`, new Date().getTime(), 'with query:', query, 'result:', response)
        return this.store
      }),
    )

    const events$ = merge(this.created$, this.updated$, this.removed$, this.patched$).pipe(
      tap(values => console.log(`${this.TAG} ${this.id}/${this.feathersService.path}: new event: values`, values)),
      debounceTime(100)
    )

    return merge(dataFromFind$, events$).pipe(
      tap(values => console.log(this.TAG, `${this.id}/${this.feathersService.path} find() before filter, values:`, values, this)),
      debounceTime(100),
      shareReplay({refCount: true, bufferSize: 1}),
      tap(values => console.log(this.TAG, `${this.id}/${this.feathersService.path} find() right before filter, values:`, values, this)),
      map(values => {
        if (values === undefined) {
          return this.store
        }
        return values
      }),
      filter(values => values !== undefined),
      tap(values => console.log(this.TAG, `${this.id}/${this.feathersService.path} find(), values:`, values, this)),
      tap(_ => this.allowEvents = true)
    )
  }

  protected onCreated(record: RecordType): RecordType[] {
    console.log(this.TAG, `${this.id}/${this.feathersService.path} onCreated`, record, 'dataStore:', this.store, this)
    this.store?.push(record)
    return this.store
  }
  protected onUpdated(record: RecordType): RecordType[] {
    console.log(this.TAG, `${this.id}/${this.feathersService.path} onUpdated record:`, record, 'dataStore:', this.store, this)
    return this.onCreated(record)
  }
  protected onPatched(record: RecordType): RecordType[] {
    console.log(this.TAG, `${this.id}/${this.feathersService.path} onPatched record:`, record, 'dataStore:', this.store, this)
    return this.onCreated(record)
  }
  protected onRemoved(record: RecordType): RecordType[] {
    console.log(this.TAG, `${this.id}/${this.feathersService.path} onRemoved record:`, record, 'dataStore:', this.store, this)
    return this.store
  }

}
