import * as ical from 'ical.js'

export class ICALHelper {
  // see http://mozilla-comm.github.io/ical.js/api/ICAL.Duration.html for clues

  public static getIcalDuration(milliseconds: number) {
    const seconds: number = Math.floor(milliseconds / 1000)
    return ical.Duration.fromData({seconds}).toICALString()
  }
}
