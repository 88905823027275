import {Injectable} from '@angular/core'
import {ToastController} from '@ionic/angular'

export interface PresentToastOpts {
  message: string,
  cssClass,
  position?: 'bottom' | 'middle' | 'top',
  duration?: number,
  icon?: string
}

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastCtrl: ToastController) {
  }

  async presentToast(opts: PresentToastOpts) {
    const toast = await this.toastCtrl.create({
      message: opts.message,
      duration: opts.duration,
      icon: opts.icon,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {
          }
        }
      ],
      cssClass: opts.cssClass,
      position: opts.position
    })
    await toast.present()
    return toast
  }
  async presentSimpleToast(message: string, position: 'bottom' | 'middle' | 'top' = 'bottom', duration = 3000) {
    const toast = await this.toastCtrl.create({
      message,
      duration,
      position
    })
    await toast.present()
    return toast
  }}
