import {User} from './user'
import {FeathersModel} from './feathersModel'
import {DateUtils} from '../services/date-utils.service'
import {createIntlComparer, DEFAULT_LOCALES} from '../services/utils'

// See https://coryrylan.com/blog/rich-domain-models-with-typescript

export class Client extends FeathersModel {
  static serviceName = 'clients'
  static intlSortComparer = createIntlComparer(DEFAULT_LOCALES, {sensitivity: 'accent'})

  title: string
  photoUrl: string
  // photoUrl: URL
  birthDate: number | string | Date
  genderId: number
  dependenceLevelId: number
  maritalStatusId: number
  preferredGenderId: number // preference pohlavi pri asistenci
  keyCoordinatorId: number // klicovy pracovnik - user s roli coordinator
  keyCoordinator: User
  benefitsCardTypeId: number
  userId: string
  idCardNumber: number
  nationality: string
  childrenCount: number
  address_street: string
  address_houseNumber: string
  address_city: string
  address_postcode: string
  coordinatorNote: string
  contractedAssistanceHoursPerWeek: number
  legalCapacity: string
  legalCapacityNote: string
  needsStrongAssistant: boolean
  // cooperationStartDate: Date
  cooperationStartDate: number
  needsAdvancedAssistant: boolean
  deactivatedAt: Date;
  deactivationReason: string;
  targetGroupOkSystemCode: number

  user: User

  static sortByName(a: Client, b: Client) {
    return Client.intlSortComparer(
      a.user.lastName + ' ' + a.user.firstName,
      b.user.lastName + ' ' + b.user.firstName
    )
  }

  getCooperationStartDate(): Date {
    return new Date(this.cooperationStartDate)
  }
  setCooperationStartDate(date: Date) {
    this.cooperationStartDate = date.getTime()
  }

  turnSubresourcesIntoFrontendModels() {
    this.user = this.user ? User.createFromProps(User, this.user) : this.user
    this.keyCoordinator = this.keyCoordinator ? User.createFromProps(User, this.keyCoordinator) : this.keyCoordinator
  }

  isDeactivated() {
    return DateUtils.canBeParsedToDate(this.deactivatedAt)
  }

  get fullname(): string {
    return this.user.fullName()
  }
}
