import {FeathersBaseDataStore} from './feathersBaseDataStore'
import {defer, from, merge, Observable} from 'rxjs'
import {debounceTime, filter, map, shareReplay, tap} from 'rxjs/operators'

export class FeathersOneRecordDataStore<RecordType> extends FeathersBaseDataStore<RecordType, RecordType> {
  defaultStoreValue: RecordType

  find(query): Observable<RecordType> {
    this.query = query

    const dataFromFind$: Observable<RecordType> = defer(() => from(this.feathersService.find({query}))).pipe(
      map((response: any) => {
        console.log(`On find ${this.id}/${this.feathersService.path}:`, new Date().getTime(), 'with query:', query, 'result:', response)

        let res
        if (response !== null && 'data' in response) {
          res = response?.data || this.defaultStoreValue
        } else {
          res = response as RecordType || this.defaultStoreValue
        }

        if (Array.isArray(res)) {
          res = res[0]
        }
        this.store = res
        return this.store
      })
		)

    const events$ = merge(this.created$, this.updated$, this.removed$, this.patched$).pipe(
        tap(values => console.log(`${this.TAG} ${this.id}/${this.feathersService.path}: new event: values`, values)),
        debounceTime(100)
    )

    return merge(dataFromFind$, events$).pipe(
        tap(values => console.log(this.TAG, `${this.id}/${this.feathersService.path} find() before filter, values:`, values, this)),
        debounceTime(100),
        shareReplay({refCount: true, bufferSize: 1}),
        tap(values => console.log(this.TAG, `${this.id}/${this.feathersService.path} find() right before filter, values:`, values, this)),
        map(values => (values === undefined) ? this.store : values),
        filter(values => values !== undefined),
        tap(values => console.log(this.TAG, `${this.id}/${this.feathersService.path} find(), values:`, values, this)),
        tap(_ => this.allowEvents = true)
    )
  }

  protected onCreated(record: RecordType): RecordType {
		this.store = record
		return this.store
  }
  protected onUpdated(record: RecordType): RecordType {
    return this.onCreated(record)
  }

  protected onPatched(record: RecordType): RecordType {
    return this.onCreated(record)
  }

  protected onRemoved(record: RecordType): RecordType {
    return this.onCreated(record)
  }



}
