<div *ngIf="ionIconName" id="icon-wrapper" [ngClass]="iconAnimation">
	<ion-icon [name]="ionIconName"></ion-icon>
</div>

<div *ngIf="spinner" id="spinner-wrapper">
	<ion-spinner name="dots" color="tertiary"></ion-spinner>
</div>

<div id="message">
  {{message}}
</div>
