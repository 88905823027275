import {FeathersBaseDataStore} from './feathersBaseDataStore'
import {FeathersDataStoreHolder, FeathersFrontendModelFactory, FeathersService} from './feathers.service'
import {Paginated} from '@feathersjs/feathers'
import {merge, Observable, Subject} from 'rxjs'
import {debounceTime, filter, first, map, shareReplay, tap} from 'rxjs/operators'
import {fromPromise} from 'rxjs/internal/observable/innerFrom'
import {NewableFeathersModel} from '../shared/utils/typeUtils'
import {FeathersModel} from '../models/feathersModel'

export interface PaginatedWithAggregates<DataType, AggregateType> extends Paginated<DataType> {
  aggregates: AggregateType
}

export class FeathersPaginatedDataStore<T extends FeathersModel, AggregateT> extends FeathersBaseDataStore<T, PaginatedWithAggregates<T, AggregateT>>{
  defaultStoreValue: PaginatedWithAggregates<T, AggregateT> = {data: [], limit: undefined, skip: undefined, total: undefined, aggregates: undefined}

  constructor(
    protected feathersService: any,
    dataStoreCounter: FeathersDataStoreHolder,
  ) {
    super(feathersService, dataStoreCounter)

    this.store = this.defaultStoreValue

    const events$ = merge(this.created$, this.updated$, this.removed$, this.patched$).pipe(
      tap(values => console.log(`${this.TAG} ${this.id}/${this.feathersService.path}: new event: values`, values)),
      debounceTime(100)
    )

    const dataWithEvents$ = merge(this.dataFromFind$$.asObservable(), events$).pipe(
      tap(values => console.log(this.TAG, `${this.id}/${this.feathersService.path} find() before filter, values:`, values, this)),
      debounceTime(100),
      shareReplay({refCount: true, bufferSize: 1}),
      tap(values => console.log(this.TAG, `${this.id}/${this.feathersService.path} find() right before filter, values:`, values, this)),
      map(values => {
        if (values === undefined) {
          return this.store
        }
        return values
      }),
      filter(values => values !== undefined),
      tap(values => {
        this.store = values
        console.log(this.TAG, `${this.id}/${this.feathersService.path} find(), values:`, values, this)
      }),
      tap(_ => this.allowEvents = true)
    )

    this.output$ = merge(dataWithEvents$, this.customCallbackEvents$)
  }

  dataFromFind$$ = new Subject<PaginatedWithAggregates<T, AggregateT>>()
  private readonly output$: Observable<PaginatedWithAggregates<T, AggregateT>>

  find(query): Observable<PaginatedWithAggregates<T, AggregateT>> {
    this.query = query
    this.doQuery(this.query)
    return this.output$
  }

  requery(query) {
    this.query = {
      ...this.query,
      ...query,
    }

    this.doQuery(this.query)
  }

  /**
   * Requeries with the last executed query.
   */
  requeryLast() {
    this.doQuery(this.query)
  }

  private doQuery(query) {
    fromPromise(this.feathersService.find({query})).pipe(
      first(),
      map((response: PaginatedWithAggregates<T, AggregateT>) => {
        this.store = response || this.defaultStoreValue
        console.log(`On find ${this.id}/${this.feathersService.path}:`, new Date().getTime(), 'with query:', query, 'result:', response)
        return this.store
      }),
    ).subscribe(res => this.dataFromFind$$.next(res))
  }

  async getUnpaginatedData(recordClass: NewableFeathersModel<T>): Promise<PaginatedWithAggregates<T, AggregateT>> {
    const query = FeathersService.unpaginateQuery(this.query)
    const res: PaginatedWithAggregates<T, AggregateT> = (await this.feathersService.find({query})) || this.defaultStoreValue

    const hydrator = new FeathersFrontendModelFactory(recordClass)
    res.data = res.data.map(d => hydrator.generateModelInstanceFromFeathersData(d)) as T[]

    console.log(`On find unpaginated data ${this.id}/${this.feathersService.path}:`, new Date().getTime(), 'with query:', query, 'result:', res)

    return res
  }

  protected onCreated(record: T): PaginatedWithAggregates<T, AggregateT> {
    console.log(this.TAG, `${this.id}/${this.feathersService.path} onCreated`, record, 'dataStore:', this.store, this)
    if (record == null) {
      console.log('Null record returned')
      return
    }

    // We could rerun the query since it's paginated, but will probably need to implement server-side caching
    // On the other hand we might not need to support onCreated at all in pagination
    // TODO

    return this.store
  }

  protected onPatched(record: T): PaginatedWithAggregates<T, AggregateT> {
    // TODO
    return this.store
  }

  protected onRemoved(record: T): PaginatedWithAggregates<T, AggregateT> {
    // TODO
    return this.store
  }

  protected onUpdated(record: T): PaginatedWithAggregates<T, AggregateT> {
    // TODO
    return this.store
  }

}
