import {FeathersRecord} from '../services/feathers.service'
import {Newable} from '../shared/utils/typeUtils'
import copy from 'fast-copy'
import {deepEqual} from '../shared/utils/fast-deep-equal'

export type ModelEventSource = 'find' | 'create' | 'update' | 'patch'

export abstract class FeathersModel implements FeathersRecord {

  constructor(public id: number = null) {
  }

  set eventSource(val: ModelEventSource) {
    if (!this._eventSource) { this._eventSource = {original: val, last: val} }
    this._eventSource.last = val
  }

  get lastEventSource(): ModelEventSource {
    return this._eventSource.last
  }
  get originalEventSource(): ModelEventSource {
    return this._eventSource.original
  }
  static serviceName: string

  private lockedForChanges = false // Field to be used to lock this instance whenever there's a long-running operation on it
  // tslint:disable-next-line:variable-name
  private _eventSource?: {original: ModelEventSource, last: ModelEventSource}


  static hydrate<T extends FeathersModel>(type: Newable<T>, data: Partial<T>): T {
    return this.createFromProps(type, data)
  }

  static createFromProps<T extends FeathersModel>(type: Newable<T>, data: Partial<T>): T {
    const instance = new type()
    instance.updateFromProps(data)

    return instance
  }

  static shallowClone<T extends FeathersModel>(original: T): T {
    const cp: T = new (original.constructor as new () => T)()
    Object.assign(cp, original)
    return cp
  }

  shallowClone(): this {
    return FeathersModel.shallowClone(this)
  }

  deepClone(): this {
    return copy(this)
  }

  // Updates current instance with a plain properties object, e.g. from a form
  // Expects input in format { property: value, property2: value, ...}
  // All properties must be in correct format
  updateFromProps(props) {
    if (props) {
      Object.keys(props).forEach(key => {
        if (props[key] && props[key].length === 0) {
          this[key] = null
        } else {
          this[key] = props[key]
        }
      })
    }

    this.turnSubresourcesIntoFrontendModels()

    return this
  }

  turnSubresourcesIntoFrontendModels() {
    // Default implementation is empty but should be overridden for subclasses that have subresources.
  }

  // We could maybe have just a simple counter to ensure we unlock as many times as we locked.
  public lockChanges(){
    // if (this.lockedForChanges) {
      // console.debug('lockChanges: already locked')
    // }
    this.lockedForChanges = true
  }

  public unlockChanges() {
    // if (!this.lockedForChanges) {
      // console.debug('unlockChanges: already unlocked')
    // }
    this.lockedForChanges = false
  }

  public isLocked() {
    return this.lockedForChanges
  }

  extractChanges(originalModel: this): Partial<this> {
    const partial: Partial<this> = {}
    const keys = Object.keys(originalModel) as (keyof this)[]
    for (const key of keys) {
      if (originalModel[key] !== this[key]) {
        partial[key] = this[key]
      }
    }
    return partial
  }

  /**
   * Compares this object with another one and returns TRUE if their properties are "identical",
   * otherwise FALSE.
   * @param other Object to compare with.
   * @param onlyProps If specified, only the listed properties will be compared.
   */
  isSameAs(other: this, onlyProps?: (keyof this)[]): boolean {
    if (other !== Object(other)) { // If comparing with a non-object (e.g. undefined, null)...
      return false
    }
    let p: keyof typeof this
    for (p in this) {
      if (this.hasOwnProperty(p)) {
        if (!onlyProps || onlyProps.includes(p)) {
          if (!deepEqual(this[p], other[p])) { // This is for deep comparison
            return false
          }
        }
      }
    }
    return true
  }

}
