import {FeathersModel} from "./feathersModel";
import {ContractType} from "./contractType";
import {User} from "./user";
import {DateUtils} from "../services/date-utils.service";

export class Contract extends FeathersModel {
	static serviceName = 'contracts'

	userId: number
	contractTypeId: number
	user: User
	contractType: ContractType
	validFrom: Date
	validTo: Date
	currentlyActive: boolean = false
	jobNameOkSystemCode: number

	updateFromProps(props): this {
		super.updateFromProps(props);

		this.validFrom = new Date(this.validFrom)
		this.validTo = new Date(this.validTo)

		return this
	}

	/**
	 * Expects a collection of all contracts on a user. Computes the contract that should be currently active, based on current date and a transparent set of rules.
	 * @param contracts A collection of Contract objects with a common userId
	 */
	static computeCurrentlyActiveContract(contracts: Contract[]): Contract {
		return this.computeActiveContractForDate(contracts, new Date())
	}

	static computeActiveContractForDate(contracts: Contract[], date: Date): Contract {
		// console.log('computeActiveContractForDate', contracts, date)
		if (!contracts) return

		const userId = contracts[0].userId

		if (contracts.some(c => c.userId !== userId)) {
			console.error('Invalid argument contracts supplied, contracts originate from multiple users', contracts)
			throw new Error('Invalid argument supplied')
		}

		return contracts
			.filter(c => DateUtils.isInRange(date, {from: c.validFrom, to: c.validTo}))
			// Get minimum range
			.reduce((prevContract, contract) => {
				// console.log(prevContract, contract)
				if (!prevContract) return contract
				return prevContract.validFrom > contract.validFrom ? prevContract : contract
			}, null)
	}

	getValidDatesLengthMs() {
		console.log('getValidDatesLengthMs', this.validFrom, this.validTo)
		return this.validTo.getTime() - this.validFrom.getTime()
	}
}
