import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProgressBarModalComponent} from "./progress-bar-modal.component";
import {IonicModule} from "@ionic/angular";

@NgModule({
  declarations: [ProgressBarModalComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [ProgressBarModalComponent]
})
export class ProgressBarModalModule { }
