/**
 * To be used with arrays as array.filter(onlyUnique) to filter out duplicates
 */
export function onlyUnique(value: any, index: any, self: string[]) {
  return self.indexOf(value) === index
}

export function generatePermutations(array: string[]): string[] {
    // base case
    if (array.length === 0) return []
    if (array.length === 1) return array

    const result = [] // This array will hold the result
    for (let i = 0; i < array.length; i++) {
      const currentElement = array[i]

      // Remaining elements
      const remainingElements = array.slice(0, i).concat(array.slice(i + 1))

      // Generate all permutations for the remaining elements
      const remainingElementPermutations = generatePermutations(remainingElements)

      // Combine currentElement with each permutation of the remaining elements, and add to result
      for (const item of remainingElementPermutations) {
        result.push(currentElement + ' ' + item)
      }
    }
    return result
}
