import {Pipe, PipeTransform} from '@angular/core'
import { millisecondsToMinutes } from 'date-fns'

@Pipe({
  name: 'minutesToHours'
})
export class MinutesToHoursPipe implements PipeTransform {
  transform(minutes: number | string, digits = 2) {
    console.log(minutes)
    return (Number(minutes) / 60).toFixed(digits)
  }
}

@Pipe({
  name: 'hoursToMinutes'
})
export class HoursToMinutesPipe implements PipeTransform {
  transform(hours: number) {
    return hours * 60
  }
}

@Pipe({
  name: 'msToHHMM'
})
export class MsToHHMMPipe implements PipeTransform {
  transform(ms: number) {
    const minutes = millisecondsToMinutes(ms)
    const hours = Math.floor(minutes/60);
    const minutesLeft = minutes % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${minutesLeft < 10 ? '0': ''}${minutesLeft}`
  }
}
