import {inject, Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router'
import {Observable} from 'rxjs'
import {ToggleableModulesService} from '../../features/config/toggleable-modules.service'
import {ToggleableModulesName} from '../../features/config/ToggleableModules'
import {first, map, tap} from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ActiveModuleGuardService {
  static TAG = 'ActiveModuleGuardService'

  constructor(
    private toggleableModulesService: ToggleableModulesService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const dependencies: ToggleableModulesName[] = route.data.dependencies

    return this.toggleableModulesService.isAllActive(dependencies)
      .pipe(
        first(),
        tap(e => console.log(ActiveModuleGuardService.TAG, 'canActivate', dependencies)),
        map(result => {
          if (!result) return this.router.parseUrl('/menu/app/tabs/welcome')
          return true
        })
      )
  }
}

export const ActiveModuleGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(ActiveModuleGuardService).canActivate(next, state)
}
