import {Assistant} from './assistant'
import {RruleHelper} from '../features/recurrence/rruleHelper'
import {RecurrentFeathersModel} from './RecurrentFeathersModel'
import {DateRange, DateUtils} from '../services/date-utils.service'
import {AssistantAssignmentList} from './assistantAssignment'
import {Client} from './client'
import {addDays, addMilliseconds, isSameMinute, subDays} from 'date-fns'
import {MsToHHMMPipe} from '../shared/pipes/timeconversion.pipe'
import {createIntlComparer, DEFAULT_LOCALES} from '../services/utils'

export class AssistanceOccupation {
  dateFrom: Date
  dateTo: Date
  client: Client

  public static sortByStartDate(a: AssistanceOccupation, b: AssistanceOccupation) {
    if (a.dateFrom < b.dateFrom) {
      return -1
    }
    return 1
  }

}

export class AssistantAvailability extends RecurrentFeathersModel {
  static serviceName = 'assistant-availabilities'
  static intlSortComparer = createIntlComparer(DEFAULT_LOCALES, {sensitivity: 'accent'})
  private TAG = 'AssistantAvailability'

  constructor(
    public id: number = null,
  ) {
    super(id)
  }

  assistantId?: number
  assistant?: Assistant
  assistanceOccupations?: AssistanceOccupation[]
  // tslint:disable-next-line:variable-name
  dt_start?: Date
  // tslint:disable-next-line:variable-name
  dt_end?: Date
  note?: string
  dateStart: Date
  timeStart: Date
  dateEnd: Date
  timeEnd: Date
  remainingVacantTimes: DateRange[]

  assistantAssignments: AssistantAssignmentList
  // Only declared property for DTO reasons
  assignments?: any

  public static expandAvailabilitiesWhichEngulfRange(availabilities: AssistantAvailability[], engulfedRange: DateRange) {
    const expanded: AssistantAvailability[] = []
    // console.log(engulfedRange.from)
    // console.log(subDays(engulfedRange.from, 0))
    // console.log(subDays(engulfedRange.from, 1))
    // console.log(subDays(engulfedRange.from, 2))
    // console.log(addDays(engulfedRange.from, 0))
    // console.log(addDays(engulfedRange.from, 1))
    // console.log(addDays(engulfedRange.from, -1))
    const limitRange: DateRange = {from: subDays(engulfedRange.from, 2), to: addDays(engulfedRange.to, 2)}

    availabilities.forEach((aa: AssistantAvailability) => {
      if (aa.rrule) {
        expanded.push(...(AssistantAvailability.expandAvailabilityByRrules(aa, limitRange).filter(a => {
          const availabilityRange: DateRange = {from: a.getDateFrom(), to: a.getDateTo()}
          return DateUtils.isInRange(engulfedRange.from, availabilityRange) && DateUtils.isInRange(engulfedRange.to, availabilityRange)
        })))
      } else {
        expanded.push(aa)
      }
    })
    return expanded

  }

  public static expandAvailabilitiesByRrules(availabilities: AssistantAvailability[], range?: DateRange, expandParentsAsOccurrences = false) {
    const expanded: AssistantAvailability[] = []

    availabilities.forEach((aa: AssistantAvailability) => {
      if (aa.rrule) { // TODO check for RRULE validity
        expanded.push(...AssistantAvailability.expandAvailabilityByRrules(aa, range, expandParentsAsOccurrences))
      } else {
        expanded.push(aa)
      }
    })
    return expanded
  }

  private static expandAvailabilityByRrules(parent: AssistantAvailability, range?: DateRange, expandParentAsOccurrence = false) {
    const expanded: AssistantAvailability[] = []

    try {
      const occurenceStartDates: Date[] = RruleHelper.expandRecurrentFeathersModel(parent as RecurrentFeathersModel, range)

      const parentStartDate = parent.getDateFrom()
      const parentDurationMs = parent.getDateTo().getTime() - parent.getDateFrom().getTime()

      // console.debug('expandAssistantAvailabilityByRrules occurrenceStartDate', occurenceStartDates, parent)

      occurenceStartDates.forEach((occurrenceStartDate) => {
        // console.log(occurrenceStartDate)

        if (!expandParentAsOccurrence && isSameMinute(occurrenceStartDate, parentStartDate)) { // Means that current occurrence is the first occurrence in the series
          expanded.push(AssistantAvailability.generateFirstOccurrence(parent))
        } else {
          expanded.push(AssistantAvailability.generateOccurrence(parent, occurrenceStartDate, addMilliseconds(occurrenceStartDate, parentDurationMs)))
        }

      })

      return expanded
    } catch (e) {
      console.error(parent, e)
      return []
    }
  }

  static expandAvailabilitiesAndFilterByRange(aa: AssistantAvailability[], range: DateRange) {
    return this.expandAvailabilitiesByRrules(aa, range).filter(a => DateUtils.dateRangeOverlaps(range, a.getPlannedRange()))
  }

  getDateFrom(): Date {
    return this.dt_start
  }
  getDateTo(): Date {
    return this.dt_end
  }

  updateFromProps(props): this {
    super.updateFromProps(props)

    this.dt_start = this.dt_start ? new Date(this.dt_start) : null
    this.dt_end = this.dt_start ? new Date(this.dt_end) : null

    return this
  }

  public setTimes(start?: Date, end?: Date) {
    if (start) {
      this.dt_start = start
    }
    if (end) {
      this.dt_end = end
    }
    this.initTimes()
  }

  public initTimes() {
    if (this.dt_start) {
      this.timeStart = this.dt_start
      this.dateStart = this.dt_start
    }
    if (this.dt_end) {
      this.timeEnd = this.dt_end
      this.dateEnd = this.dt_end
    }
  }

  public initAssistantFromAssistantId(assistants: Assistant[]) {
    if (this.assistantId && !this.assistant) {
      this.assistant = assistants.find((a: Assistant) => {
        return a.id === this.assistantId
      })
      // this.initClientDefaults(this.assistant) // TODO: isn't this needed for AA Editor?
    }

    return this
  }

  turnSubresourcesIntoFrontendModels() {
    this.assistant = this.assistant ? Assistant.createFromProps(Assistant, this.assistant) : this.assistant

    // console.log('this.assignments before', this.id, this.assignments, this.assistantAssignments)
    this.convertAssignmentToAssistantAssignmentList()
    // console.log('this.assignments after', this.id, this.assignments, this.assistantAssignments)
  }

  getRemainingVacantTimes(): DateRange[] {
    let remainingVacantDates: DateRange[] = [{from: this.getDateFrom(), to: this.getDateTo()}]

    if (!this.assistanceOccupations) { return remainingVacantDates }

    this.assistanceOccupations.forEach(a => {
      remainingVacantDates = DateUtils.subtractDateRangeFromDateRanges(remainingVacantDates, {from: a.dateFrom, to: a.dateTo})
    })
    // console.log('remainingVacantDates', remainingVacantDates)
    return remainingVacantDates
  }

  isOccupiedAt(range: DateRange) {
    return DateUtils.dateRangesAnyOverlap(this.getOccupiedTimes(), [range])
  }

  getOccupiedTimes(): DateRange[] {
    if (!this.assistanceOccupations) { return [] }

    const occupiedTimes: DateRange[] = []
    this.assistanceOccupations.forEach(a => {
      occupiedTimes.push({ from: a.dateFrom, to: a.dateTo })
    })

    return occupiedTimes
  }

  private assistanceOccupationsFromAssistanceAssignments(): AssistanceOccupation[] {
    const occupations: AssistanceOccupation[] = []
    // console.log(this)

    if (this.assistantAssignments) {
      for (const assistance of this.assistantAssignments.getUniqueAssistances()) {

        // console.log(this.TAG, 'assistanceOccupationsFromAssistanceAssignments', assistance)
        const o = new AssistanceOccupation()
        o.dateFrom = assistance.getDateFrom()
        o.dateTo = assistance.getDateTo()
        o.client = assistance.client
        occupations.push(o)
      }
    }

    // console.log('assistanceOccupationsFromAssistanceAssignments', this);
    //
    return occupations
  }

  public convertAssignmentToAssistantAssignmentList() {
    if (this.assignments) {
      this.assistantAssignments = AssistantAssignmentList.initializeAsFeathersModels(this.assignments)
      this.assignments = null
      delete this.assignments
    }
  }

  public static convertAssignmentToAssistantAssignmentList(assistantAvailability: AssistantAvailability) {
    if (assistantAvailability.assignments) {
      assistantAvailability.assistantAssignments = AssistantAssignmentList.initializeAsFeathersModels(assistantAvailability.assignments)
      assistantAvailability.assignments = null
      delete assistantAvailability.assignments
    }
    return assistantAvailability
  }

  isAllowedToBeDeleted() {
    // console.log('this.assistanceOccupations', this.assistanceOccupations, this)
    return (!this.assistanceOccupations || this.assistanceOccupations?.length === 0) || this.isRecurrentButNotException()
  }

  static sortByAssistantName(a: AssistantAvailability, b: AssistantAvailability) {
    return AssistantAvailability.intlSortComparer(
      a.assistant.user.lastName + ' ' + a.assistant.user.firstName,
      b.assistant.user.lastName + ' ' + b.assistant.user.firstName
    )
  }

  static generateOccurrence(parent: AssistantAvailability, startDate: Date, endDate: Date) {
    const availability = AssistantAvailability.createFromProps(AssistantAvailability, parent)
    availability.id = null

    availability.setTimes(startDate, endDate)

    availability.recurrenceParentId = parent.id
    availability.recurrenceException = null
    availability.rrule = null
    availability.recurrenceExceptionDate = availability.getDateFrom()

    return availability
  }

  static generateFirstOccurrence(parent: AssistantAvailability) {
    const availability = AssistantAvailability.createFromProps(AssistantAvailability, parent)
    availability.recurrenceParentId = null

    return availability
  }

  getDurationMs(): number {
    if (!this.dt_start || !this.dt_end) return 0
    return (this.dt_end.getTime() - this.dt_start.getTime())
  }

  getDurationMinutes(): number {
    const ms = this.getDurationMs()
    return ms / 1000 / 60
  }

  getDurationHours(): string {
    return (this.getDurationMinutes() / 60).toFixed(2)
  }

  static sumDurationHHMM(availabilities: AssistantAvailability[]): string {
    if (!availabilities) return '00:00'
    const durationMsTotal = availabilities.reduce((total, curr) => {
      return total + curr.getDurationMs()
    }, 0)
    return new MsToHHMMPipe().transform(durationMsTotal)
  }

  static sumDurationHHDecimalMinutes(availabilities: AssistantAvailability[], decimalPlaces = 2) {
    if (!availabilities) return '00.00'
    const durationMsTotal = availabilities.reduce((total, curr) => {
      return total + curr.getDurationMs()
    }, 0)
    return (durationMsTotal / 1000 / 60 / 60).toFixed(2)
          // return new MsToHHMMPipe().transform(durationMsTotal)
  }

  generateFirstOccurrence(): AssistantAvailability {
    return AssistantAvailability.generateFirstOccurrence(this)
  }

  generateOccurrence(occurrenceStartDate: Date, occurrenceEndDate: Date): AssistantAvailability {
    return AssistantAvailability.generateOccurrence(this, occurrenceStartDate, occurrenceEndDate)
  }
}
