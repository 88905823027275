import {inject, Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router'

import {FeathersService} from '../feathers.service'
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private router: Router,
    public feathersService: FeathersService,
  ) {
  }

  // Guard method for views that must be logged in (e.g. user and data)
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const retUrl = state.url
    console.log('AuthGuardService(%s): checking saved auth token...', retUrl)
    const redirectUrl = '/login' // TODO: (soon) this should not be defined in the service. Refactor it out of here.

    return this.feathersService.reauthenticate()
      .then(() => {
        // Ok
        console.log('AuthGuardService(%s): has valid saved auth token, ok.', retUrl)
        return true
      })
      .catch((err) => {
        console.error('AuthGuardService err', err)
        // Force auth guard
        const urlTree = this.router.createUrlTree([redirectUrl], { queryParams: { retUrl } })
        console.log('AuthGuardService(%s): no valid saved auth token, redirecting to %s.', retUrl, urlTree.toString())
        return urlTree // Angular >= 7.1 router
      })
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(AuthGuardService).canActivate(next, state)
}
