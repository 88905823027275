import {AssignmentsManagerService} from '../features/assignments/assignments-manager.service'
import {Application, TransportConnection} from '@feathersjs/client'

const servicesWithCustomMethods = [
  {path: AssignmentsManagerService.SERVICE_PATH, methods: ['advanceStatus', 'create', 'remove', 'removeAllFromAssistance', 'advanceAssistanceStatus', 'cancelAssistance']},
  {path: 'financial-reports', methods: ['find', 'create', 'getSumsForAssistance']},
  {path: 'editor-assistances', methods: ['find', 'get', 'create', 'patch', 'remove', 'createBundleChild', 'splitSeries']}
]

export function declareCustomMethodsOnBackendServices(client: Application<any, any>, socketClient: TransportConnection<any>) {
  for (const service of servicesWithCustomMethods) {
    client.use(service.path, socketClient.service(service.path), {methods: service.methods})
  }
}


