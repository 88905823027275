import {Component, Input, OnInit} from '@angular/core'
import {IonicModule, ModalController} from '@ionic/angular'
import {NgClass, NgIf} from '@angular/common'

@Component({
  selector: 'app-advanced-toast',
  templateUrl: './advanced-toast.component.html',
  styleUrls: ['./advanced-toast.component.scss'],
  imports: [
    IonicModule,
    NgIf,
    NgClass
  ],
  standalone: true
})
export class AdvancedToastComponent implements OnInit {
  @Input() message: string
  @Input() ionIconName?: string
  @Input() durationMs?: number
  @Input() iconAnimation?: 'spin'
  @Input() spinner = false

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    if (this.durationMs) {
      setTimeout(async () => {
        await this.modalCtrl.dismiss()
      }, this.durationMs)
    }
  }

}
