import {DateRange} from '../../services/date-utils.service'
import {RruleHelper} from './rruleHelper'
import {RecurrentFeathersModel} from '../../models/RecurrentFeathersModel'
import {addMilliseconds, isSameMinute} from 'date-fns'
import {XOR} from '../../shared/utils/typeUtils'

export type ParsedURID = XOR<
  { id: number },
  { recurrenceParentId: number, plannedFrom: Date, recurrenceExceptionDate?: Date }
>

export class RecurrenceUtil {
  /**
   * Generates a unique ID for a possibly recurrent entity, regardless whether it already has an id assigned by a database or is an id-less expanded instance.
   * URID spec:
   *  If the entity has already assigned id, even if it is recurrent, URID == id
   *  If the entity is an expanded recurrent instance, return string in format 'recurrenceParentId-plannedFrom-recurrenceExceptionDate', where the dates are stringified into timestamps.
   *  For backwards compatibility, we need to support entities without recurrenceExceptionDate, so if it's not present, the result format is 'recurrenceParentId-plannedFrom'.
   */
  static generateURID(recurrentEntity: RecurrentFeathersModel): string {
    if (recurrentEntity.id) return recurrentEntity.id.toString()
    if (!recurrentEntity.recurrenceParentId) {
      // console.error('#generateURID: missing recurrenceParentId', recurrentEntity)
      return null // New entity that is not recurrent nor expanded from recurrentParent
    }
    if (!!recurrentEntity.recurrenceExceptionDate) {
      return `${recurrentEntity.recurrenceParentId}-${recurrentEntity.getDateFrom().getTime()}-${recurrentEntity.recurrenceExceptionDate.getTime()}`
    } else {
      return `${recurrentEntity.recurrenceParentId}-${recurrentEntity.getDateFrom().getTime()}`
    }
  }

  /**
   * Similar to generateURID function with one key difference - if the supplied entity is a recurrence parent, it will have the URID generated in the occurrence format instead of standalone format.
   */
  static generateURIDIncludingFirst(recurrentEntity: RecurrentFeathersModel) {
    if (recurrentEntity.id && !recurrentEntity.rrule) return recurrentEntity.id.toString()
    if (!recurrentEntity.id && !recurrentEntity.recurrenceParentId) {
      // console.error('#generateURID: missing recurrenceParentId', recurrentEntity)
      return null // New entity that is not recurrent nor expanded from recurrentParent
    }

    if (recurrentEntity.id) {
      if (!!recurrentEntity.recurrenceExceptionDate) {
        return `${recurrentEntity.id}-${recurrentEntity.getDateFrom().getTime()}-${recurrentEntity.recurrenceExceptionDate.getTime()}`
      } else {
        return `${recurrentEntity.id}-${recurrentEntity.getDateFrom().getTime()}`
      }
    }

    if (!!recurrentEntity.recurrenceExceptionDate) {
      return `${recurrentEntity.recurrenceParentId}-${recurrentEntity.getDateFrom().getTime()}-${recurrentEntity.recurrenceExceptionDate.getTime()}`
    } else {
      return `${recurrentEntity.recurrenceParentId}-${recurrentEntity.getDateFrom().getTime()}`
    }

  }


  static parseURID(URID: string): ParsedURID | null {
    const components = URID?.split('-').map(i => parseInt(i, 10))
    if (!components || !components.length) return null
    switch (components.length) {
      case 1:
        return {id: components[0]}
      case 2: // For backwards compatibility
        return {recurrenceParentId: components[0], plannedFrom: new Date(components[1])}
      case 3:
        return {recurrenceParentId: components[0], plannedFrom: new Date(components[1]), recurrenceExceptionDate: new Date(components[2])}
    }
  }

  static expand<T extends RecurrentFeathersModel>(parent: T, range?: DateRange, expandParentAsOccurrence = false): T[] {
    if (!parent.rrule) return [parent]

    const expanded: T[] = []

    const occurrenceStartDates: Date[] = RruleHelper.expandRecurrentFeathersModel(parent, range)

    const parentDurationMs = parent.getDateTo().getTime() - parent.getDateFrom().getTime()

    occurrenceStartDates.forEach((occurrenceStartDate) => {
      if (!expandParentAsOccurrence && isSameMinute(occurrenceStartDate, parent.getDateFrom())) { // Means that current occurrence is the first occurrence in the series
        expanded.push(parent.generateFirstOccurrence())
      } else {
        expanded.push(parent.generateOccurrence(occurrenceStartDate, addMilliseconds(occurrenceStartDate, parentDurationMs)))
      }
    })

    if (range) {
      return expanded.filter(a => a.getDateFrom() >= range.from && a.getDateFrom() <= range.to) // In order to filter out new events coming from feathers that are not in range
    }
    return expanded
  }

}
